<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
            spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 pt-8 text-left>
            <span style="font-size: 25px;font-weight: bolder;">APPLICATIONS </span>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable dense outlined readonly v-model="fromDate" v-bind="attrs" background-color="white"
                  v-on="on" @click:clear="from = null" style="color: black">
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="custom-label-text" style="color: black; font-size: 14px">
                      From Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="fromDate" color="#13736f" @change="menu1 = false"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" max-width="290" elevation="0">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field clearable label="To Date" readonly dense background-color="white" color="#13736f" outlined
                  v-model="toDate" v-bind="attrs" v-on="on" @click:clear="from = null">
                  <template v-slot:append>
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="custom-label-text" style="color: black; font-size: 14px">
                      To Date</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="toDate" color="#13736f" @change="menu2 = false" :min="fromDate"></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xl3 lg2 md3 pr-1 sm3 xs12 align-self-center>
            <v-text-field v-model="keyword" outlined dense hide-details clearable color="orange" label="Search "
              class="custom-text-field" style="border-radius: 0%" placeholder="Application Number">
              <template v-slot:label>
                <span class="custom-label-text mainfont" style="color: black; font-size: 14px">Search
                </span>
              </template>
            </v-text-field>
          </v-flex>
          <!-- <v-flex xl3 lg2 md3 pr-1 sm3 xs12 align-self-center>
    <v-text-field
      v-model="keyword"
      outlined
      dense
      hide-details
      clearable
      color="orange"
      label="Search"
      class="custom-text-field"
      style="border-radius: 0%"
      placeholder="Application Number"
    >
      <template v-slot:label>
        <span class="custom-label-text mainfont" style="color: black; font-size: 14px">
          Search
        </span>
      </template>
      
      <template v-slot:append>
        <v-btn @click="searchFunction" icon>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </v-flex> -->


        </v-layout>
      </v-flex>
      <v-flex xs12>
        <!-- <v-layout wrap justify-center>
          <v-flex xs12><span style="font-size: 18px; font-weight: bolder">TASK TO BE DONE:</span>&nbsp;<span
              style="color: red;">Select Range Office
              Generate licence, proceedings and letter to RFO.</span></v-flex>
        </v-layout> -->
        <v-layout  wrap align-center justify-center>
          <v-flex xs12>
            <div style="display: flex; align-items: center;">
              <v-icon color="primary">mdi-checkbox-marked-circle</v-icon>
              <span style="font-size: 17px; font-weight: bold;">TASK TO BE DONE :</span>&nbsp;<span
                style="color: red; font-size: 16px;">Select Range Office
              Generate licence, proceedings and letter to RFO.</span>
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-4 v-for="(item, i) in list" :key="i">
        <v-layout wrap>

          <v-flex xs12>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12 lg3 pt-3 pb-3 pl-3 v-if="item.paymentStatus === 'Success'">
                  <div style="position: relative;">
                    <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                      <div
                        style="position: absolute; top: 10px; left: 10px; padding: 5px; background-color: #228b22 ; color: white; font-weight: bold; border-radius: 5px;">
                        Payment Done</div>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 lg3 pt-3 pb-3 pl-3 v-else>

                  <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>

                </v-flex>
                <v-flex xs12 lg9 pt-3>
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex pl-4 xs12 lg3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Number

                          </span>
                          <br />
                          <span>{{ item.applicationNo }}</span>
                        </v-flex>
                        <v-flex pl-4 xs12 lg3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Name

                          </span>
                          <br />
                          <span>{{ item.applicantName }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Disrict Name

                          </span>
                          <br />
                          <span>{{ item.applicantDistrict }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Mobile Number

                          </span>
                          <br />
                          <span>{{ item.applicantMobileNo }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Email

                          </span>
                          <br />
                          <span>{{ item.applicantEmail }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Refund Amount

                          </span>
                          <br />
                          <span>{{ item.refundAmount }}Rs</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Security Deposit
                          </span>
                          <br />
                          <span>{{ item.securitydeposit }}Rs</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left pl-4 pt-4>
                          <span class="subheading" style="font-size: 16px;">
                            Shooting Fee

                          </span>
                          <br />
                          <span>{{ item.shootingfee }}Rs</span>
                        </v-flex>
                        <!-- <v-flex xs12 lg3 text-left pl-4 pt-4>
                              <span class="subheading" style="font-size: 16px;color:red">
                              Remarks
    
                              </span>
                              <br />
                              <span  style="font-size: 16px;color:red">{{ item.reason }}</span>
                            </v-flex> -->
                        <v-flex xs4 pt-7 v-if="item.paymentInitiation === 'YES' && item.paymentStatus === 'Pending'">
                          <v-icon color="green">mdi-clock-check-outline</v-icon>
                          <span style="color:green;font-weight:bold">
                            Waiting for payment approval

                          </span>
                        </v-flex>

                      </v-layout>
                    </v-flex>
                    <v-flex xs12 align-self-center>
                      <v-layout wrap justify-start>
                        <v-flex xs2 pb-4 text-center>
                          <v-btn @click="$router.push('/FilmDetailedView?id=' + item._id)" plain class="text-capitalize"
                            color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>


                        </v-flex>
                        <!-- <v-flex xs2 pb-4 text-center>
                          <v-btn @click="navigateToFilmDetailedView(item._id)" plain class="text-capitalize" color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>
                        </v-flex> -->


                        <!-- <v-flex xs3 pb-4 pl-3 text-center
                            v-if="item.paymentInitiation == 'YES' && item.paymentStatus == 'Success' && !item.rangeMahassar && !item.rangeReport">
                            <v-autocomplete clearable dense outlined label="Range" color="black" :items="range"
                              v-model="rangeid" item-text="officeName" item-value="_id" item-color="#FF1313" hide-details
                              class="custom-autocomplete mainfont">
                              <template v-slot:label>
                                <span class="custom-label-text" style="color: black; font-size: 14px">
                                  Send to Range
  
                                </span>
                              </template>
                              <template v-slot:append>
                                <v-icon color="black">mdi-chevron-down</v-icon>
                              </template>
                            </v-autocomplete>
                            <v-btn plain class="text-capitalize" @click="forwardToRange(item._id)" color="orange">
                              <v-icon color="orange">mdi-share</v-icon>&nbsp;
                              <span>FORWARD</span>
                            </v-btn>
                          </v-flex> -->

                        <!-- <v-flex xs4 pb-4 text-center v-else-if="">
                            <v-btn plain class="text-capitalize" @click="forwardToUsers(item._id), deletedialog = true"
                              color="orange">
                              <v-icon color="orange">mdi-share</v-icon>&nbsp;
                              <span>FORWARD</span>
                            </v-btn>
                          </v-flex> -->





                        <!-- <v-flex xs4 pb-4 text-center v-else-if="item.paymentInitiation === 'NO' && item.paymentStatus === 'Pending'">
                            <v-btn plain class="text-capitalize" @click="forwardToUsers(item._id), deletedialog = true"
                              color="orange">
                              <v-icon color="orange">mdi-share</v-icon>&nbsp;
                              <span>FORWARD</span>
                            </v-btn>
                          </v-flex>
  
                          <v-flex xs4 pb-4 text-center v-else>
                            <v-btn plain class="text-capitalize" @click="forwardToUsersone(item._id), deletedialog = true"
                              color="orange">
                              <v-icon color="orange">mdi-share</v-icon>&nbsp;
                              <span>FORWARD</span>
                            </v-btn>
                          </v-flex> -->

                        <!-- <v-flex xs2 pb-4 text-center pl-5>
                            <v-btn plain class="text-capitalize" @click="setCurIdToDelete(item._id), (rejectdialog = true)"
                              color="red">
                              <v-icon size="140%" color="red">mdi-block-helper</v-icon>&nbsp;
                              <span>REJECT</span>
                            </v-btn>
                          </v-flex> -->


                        <!-- <v-flex xs2 pb-4 text-center v-if="item.paymentInitiation !== 'YES'">
                            <v-btn plain class="text-capitalize" @click="settoforward(item._id), (procceddialog = true)"
                              color="green">
                              <v-icon size="140%" color="green">mdi-check</v-icon>&nbsp;
                              <span>PROCEED</span>
                            </v-btn>
                          </v-flex>
  
                          <v-flex xs2 pb-4 text-center pl-5 v-if="item.rangeMahassar && item.rangeReport">
                            <v-btn class="text-capitalize" @click="refund(item._id)" color="green">
                              <v-icon size="140%" color="white">mdi-check</v-icon>&nbsp;
                              <span style="color:white">REFUND</span>
                            </v-btn>
                          </v-flex> -->

                      </v-layout>
                    </v-flex>

                  </v-layout>

                </v-flex>

              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-dialog :retain-focus="false" persistent v-model="agreementdialog" max-width="600px">
        <v-card>
          <v-layout wrap>
            <v-flex>
              {{
                agreement
              }}
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>


      <v-dialog :retain-focus="true" persistent v-model="procceddialog" max-width="730px">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs8 text-left pt-5>
              <span class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want
                to proceed this application for
                <br />
                1.&nbsp;Fee Payment
                <br />
                2.&nbsp;Agreement Execution
              </span>
            </v-flex>
          </v-layout>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="procceddialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="proceed(appids2)">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="700px">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs8 text-left pt-5>
              <span class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want
                to forward?
              </span>

            </v-flex>
            <v-flex xs8 pt-6 pr-4 class="mainfont">
              <v-autocomplete outlined dense color="black" :items="usernames" v-model="selectedUsername" item-text="name"
                placeholder="Select a Role" style="font-family: mainfont;" item-value="name" item-color="#FF1313"
                hide-details clearable class="custom-autocomplete">
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs8 pt-5 pr-4 class="mainfont">
              <v-text-field placeholder="reason" v-model="reason" dense class="mainfont" outlined color="black">
              </v-text-field>
            </v-flex>
          </v-layout>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="forwardToUsers2(appids)">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="700px">
        <v-card>
          <v-layout wrap justify-center>
            <v-flex xs8 text-left pt-5>
              <span class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">
                Are you sure you want
                to forward?
              </span>

            </v-flex>
            <v-flex xs8 pt-6 pr-4 class="mainfont">
              <v-autocomplete outlined dense color="black" :items="usernames" v-model="selectedUsername" item-text="name"
                placeholder="Select a Role" style="font-family: mainfont;" item-value="name" item-color="#FF1313"
                hide-details clearable class="custom-autocomplete">
                <template v-slot:append>
                  <v-icon color="black">mdi-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </v-flex>

            <v-flex xs8 pt-5 pr-4 class="mainfont">
              <v-text-field placeholder="reason" v-model="reason" dense class="mainfont" outlined color="black">
              </v-text-field>
            </v-flex>
          </v-layout>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="forwardToUsers2(appids)">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout wrap>
      <v-flex text-center xs12 v-if="list.length < 1">
        <span>No Applications !</span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 v-if="list.length > 0">
        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import CryptoJS from "crypto-js";
export default {
  data() {
    return {
      path: localStorage.getItem("path"),
      curIdToDelete: null,
      name: null,
      reason: null,
      editdialog: false,
      barlist: [],
      // reason:null,
      edit: [],
      userProfileTabs: null,
      procceddialog: false,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],
      pages: 0,
      usernames: [],
      selectedUsername: null,
      dialog1: false,
      preview: null,
      agreementdialog: false,
      agreement: null,
      rejectdialog: false,
      tab: null,
      barlist1: [],
      gender: ["Male", "Female", "Transgender"],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: null,
      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      currentPage: 1,
      showSnackBar: false,
      users: [],
      range: [],
      username1: null,
      username2: null,
      rangeid: null,
      divisionid: null,
      dialog2: false,
      viewdialog: false,
      keyword: "",
    };
  },

  mounted() {
    this.getList();
    this.getRange();
    // this.getUsers();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
    keyword() {
      this.getList();
    },
  },
  computed: {
    getUserType() {
      const uType = localStorage.getItem("uType");

      if (uType === "777") {
        return "admin";
      } else if (uType === "772") {
        return "user";
      } else if (uType === "7741") {
        return "section";
      }
      else if (uType === "7742") {
        return "superclerk";
      }
      else {
        return "division";
      }
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },


  methods: {
  //   navigateToFilmDetailedView(id) {
    // Encrypt the ID before navigating
  //   const encryptedId = CryptoJS.AES.encrypt(id.toString(), 'encryptionSecret').toString();
  //   this.$router.push({
  //     path: '/FilmDetailedView',
  //     query: {
  //       id: encryptedId
  //     }
  //   });
  // },
    setCurIdToDelete(id) {
      this.curIdToDelete = id;
    },

    settoforward(id) {
      this.curIdToDelete = id;
    },

    proceed() {
      const idToDelete = this.curIdToDelete;
      if (!idToDelete) {
        return;
      }
      axios({
        url: "/division/payment/initiation/",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: idToDelete,
          reason: this.reason,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    refund(appid) {

      axios({
        url: "/refund/",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appid,
          rangeid: this.rangeid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    forwardToRange(appid) {

      axios({
        url: "/range/forward/",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appid,
          rangeid: this.rangeid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getRange() {
      this.appLoading = true;

      axios({
        method: "post",
        url: "/get/range",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          divisionid: this.divisionid,
          path: this.path,
        },
      })
        .then((response) => {
          this.range = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteItem() {
      const idToDelete = this.curIdToDelete;
      if (!idToDelete) {
        return;
      }
      axios({
        url: "/division/reject",

        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: idToDelete,
          reason: this.reason,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    forwardToUsers(_id) {
      this.getUsers(_id);
      this.appids = _id;
    },
    forwardToUsersone(_id) {
      this.getUsersone(_id);
      this.appids = _id;
    },


    forwardToUsers2(appids) {
      this.forward(appids);
    },
    forwardToUsers3(appids2) {
      this.forward2(appids2);
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getUsersone(appid) {
      axios({
        method: "post",
        url: "/dfo/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appid,
        },
      })
        .then((response) => {
          this.usernames = [
            // { name: `${response.data.user.username} (User)`, role: "User", userId: response.data.user._id },
            { name: `${response.data.superintendent.username} (Superintendent)`, role: "Superintendent", userId: response.data.superintendent._id },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getUsers(appid) {
      axios({
        method: "post",
        url: "/dfo/forward/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appid,
        },
      })
        .then((response) => {
          this.usernames = [
            { name: `${response.data.user.username} (User)`, role: "User", userId: response.data.user._id },
            { name: `${response.data.superintendent.username} (Superintendent)`, role: "Superintendent", userId: response.data.superintendent._id },
          ];
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    forward(appids) {
      if (!this.selectedUsername) {
        this.$refs.snackbar.showSnackbar("Please select a user to forward to.");
        return;
      }
      const selectedUser = this.usernames.find(user => user.name === this.selectedUsername);
      if (!selectedUser) {
        return;
      }

      axios({
        method: "post",
        url: "/forward/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appids,
          forwardto: selectedUser.userId,
          reason: this.reason,
        }
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    forward2(appids2) {

      axios({
        method: "post",
        url: "/division/payment/initiation/",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: appids2,

        }
      })
        .then((response) => {
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.error(err);
        });
    },

    // getList() {
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/forwarded/apps",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     data: {
    //       status: "yes",
    //       categoryId: this.categoryId,
    //       page: this.currentPage,
    //       fromDate: this.fromDate,
    //       toDate: this.toDate,
    //       limit: 10,
    //     },
    //   })
    //     .then((response) => {
    //       this.list = response.data.data.map((item) => {
    //         const date = new Date(item.selectedDate);
    //         item.selectedDate = date.toLocaleDateString();
    //         item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
    //         return item;
    //       });
    //       this.totalData = response.data.totalLength;
    //       this.selectedDatesArray = this.list.map((item) => item.selectedDate);
    //       this.pages = Math.ceil(this.totalData / response.data.limit);
    //       this.appLoading = false;
    //     })
    //     .catch((err) => {
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    getList() {
      this.appLoading = true;

      // Convert fromDate and toDate to timestamp format

      // var formattedFromDate = new Date(this.fromDate).toISOString();
      // if (this.toDate != null) {
      //   var formattedToDate = new Date(this.toDate).toISOString();
      // }
      // var data = {};
      // data['status'] = "yes";
      // if(this.cate)
      // data['categoryId'] = this.categoryId;


      axios({
        method: "POST",
        url: "/forwarded/apps",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          task: "T6",
          status: "yes",
          categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          applicationNo: this.keyword,
          limit: 5,
        },
      })
        .then((response) => {
          this.list = response.data.data.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
          });
          this.selectedDatesArray = this.list.map((item) => item.selectedDate);
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },


  },
};
</script>
      
<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;

}

.heading {
  font-family: mainfont;
  color: rgb(229, 111, 8);
  text-transform: none;
  font-size: 25px;

}
</style>